import React, { useRef, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { bool } from 'prop-types';

import { BiArrowBack } from 'react-icons/bi';
import { Box, Text, Center, Flex, Divider, useToast, Button, Spinner } from '@chakra-ui/react';

import QRCode from 'react-qr-code';
import loadable from '@loadable/component';

import useLang from '@/hooks/useLang';

import { BUTTON_PROPS } from '@/constants/colors';
import { THE_BRIDE, WEDDING_RESEPSI_DATE } from '@/constants';

import txt from './locales';
import QRButton from '../QRCodeButton';

// @important - please do don't change to CDN github
// we will got failed export image because CORS issue
import {BG_DESKTOP_VIEW} from '@/constants/assets';
import generateShift from '@/hooks/useShift';
import useQueryString from '@/hooks/useQueryString';
import { useGuest } from '@/context/guest';

const LazyExportPNG = loadable(() =>
  import(/* webpackChunkName: "invitato-export-png" */ './ExportPNG'),
);

/**
 * Function to render QRCard version 2
 * Support:
 * - Download QR as PNG
 * - Open QR in new page
 */
function QRCardSection({ isDirectLink, ...rest }) {
  const toast = useToast();
  const { getQueryValue } = useQueryString();
  const isAutoDownload = getQueryValue('autoDownload') == '1';

  const lang = useLang();
  const ticketRef = useRef(null);
  const isShowToaster = useRef(false);

  const { isLoading, guest } = useGuest();

  const { code, name, desc, shift } = guest;

  useEffect(() => {
    if (
      !isLoading &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isDirectLink &&
      !isShowToaster.current &&
      !isAutoDownload
    ) {
      isShowToaster.current = true;
      toast({
        isClosable: true,
        status: 'warning',
        duration: null,
        description: (
          <Text fontSize="sm">
            <span dangerouslySetInnerHTML={{ __html: txt.darkmodeDesc[lang] }} />
          </Text>
        ),
      });
    }
  }, []);

  // IF code is not found, just ignore it
  if (!code) return null;

  return (
    <Box>
      {isLoading ? (
        <Center height="100px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ) : (
        <Box>
          {/* import font for QRCard */}
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,500;0,600;1,500;1,600&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <div ref={ticketRef}>
            <Box bgColor="bgSecondary" padding="6px" {...rest}>
              <Box bgColor="white" color="black" padding="6px">
                {/* QRCODE COVER SECTION */}
                <Box
                  bgImage={`url(${BG_DESKTOP_VIEW})`}
                  width="100%"
                  height="200px"
                  bgSize="cover"
                  bgPos="center"
                >
                  <Center height="200px" fontFamily="jost">
                    <Box
                      color="white"
                      textAlign="center"
                      textTransform="uppercase"
                      textShadow="1px 1px 1px black"
                    >
                      <Text fontSize="xs" letterSpacing="2px" marginTop="12px">
                        {txt.title[lang]}
                      </Text>
                      <Text fontSize="xl" letterSpacing="6px" margin="4px 0">
                        {THE_BRIDE}
                      </Text>
                      <Text fontSize="xs" letterSpacing="4px" fontWeight="light">
                        {WEDDING_RESEPSI_DATE}
                      </Text>
                    </Box>
                  </Center>
                </Box>
                {/* DETAIL QRCODE */}
                <Flex
                  fontFamily="jost"
                  fontSize="sm"
                  padding="12px 4px"
                  justifyContent="space-between"
                >
                  <Box flex="1.3">
                    <Text fontWeight="light">{txt.to[lang]}</Text>
                    <Text fontSize="xs">{name}</Text>

                    <Text marginTop="8px" fontWeight="light">
                      {txt.desc[lang]}
                    </Text>
                    <Text fontSize="xs">{desc}</Text>
                    {false && (
                      <Text fontSize="xs">{`Shift ${shift} (${generateShift(shift)})`}</Text>
                    )}

                    <Text marginTop="8px" fontWeight="light">
                      {txt.important[lang]}
                    </Text>
                    <Text fontSize="xs">{txt.importantDesc[lang]}</Text>
                  </Box>
                  <Box flex="1">
                    <Box float="right">
                      <QRCode value={code} size={125} />
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Divider opacity="1" height="2px" bgColor="bgSecondary" border="none" />
              {/* Copyright Section */}
              <Box
                fontFamily="jost"
                color="blackAlpha.900"
                textAlign="center"
                bgColor="white"
                padding="8px 0"
              >
                <Text fontSize="xs">Organized & Generated by Invitato</Text>
                <Text fontSize="xs">{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
              </Box>
            </Box>
          </div>

          <Box>
            <Text fontSize="sm" textAlign="center" margin="8px">
              <span dangerouslySetInnerHTML={{ __html: txt.downloadDesc[lang] }} />
            </Text>
            <Center>
              {isDirectLink ? (
                <QRButton
                  fontFamily="body"
                  text="Download E-ticket"
                  customQueries="&autoDownload=1"
                  bgColor="bgAlternative"
                  color="mainColorText"
                  colorScheme="blackAlpha"
                  size="sm"
                  fontSize="md"
                  fontWeight="bold"
                  fontStyle="italic"
                  padding="6px 8px"
                  {...BUTTON_PROPS}
                />
              ) : (
                <LazyExportPNG componentRef={ticketRef} autoDownload={isAutoDownload} />
              )}
            </Center>
            {!isDirectLink && (
              <Center>
                <Button
                  marginTop="16px"
                  leftIcon={<BiArrowBack />}
                  type="button"
                  size="sm"
                  fontSize="md"
                  fontStyle="italic"
                  fontWeight="bold"
                  bgColor="bgAlternative"
                  color="white"
                  colorScheme="blackAlpha"
                  onClick={() => navigate(-1)}
                >
                  {txt.back[lang]}
                </Button>
              </Center>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

QRCardSection.propTypes = {
  isDirectLink: bool,
};

QRCardSection.defaultProps = {
  isDirectLink: true,
};

export default React.memo(QRCardSection);
